.form-check.frequency-checkbox {
  position: static;
  margin-right: 10px !important;
}

.frequency-checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.frequency-checkbox label {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  background: #e3eaf3;
  cursor: pointer;
  user-select: none;
}

.frequency-checkbox input:checked + label {
  background: var(--echobox_blue);
  color: white;
}
