.form-group.edit-content-form-group {
  margin-bottom: 40px;
}

.article-state-message {
  margin-right: auto !important;
}

.article-success-state svg {
  background-color: #06966a;
  border-radius: 50%;
  color: white;
  padding: 4px;
  min-width: 15px;
  min-height: 15px;
}

.article-success-state span {
  color: #06966a;
}

.title-disabled {
  opacity: 0.5;
}
