.settings-header {
  min-height: var(--subheader__height);
  padding: 12px 0px !important;
  position: fixed !important;
  top: 0;
}

.settings-header .close-icon {
  fill: #909db5;
  margin-left: 16px;
}

.settings-header-link {
  color: #596a87;
}

.settings-header-link:hover {
  text-decoration: none;
  color: var(--echobox_blue);
}

.settings-button {
  line-height: 18px !important;
  max-height: 36px;
  margin-right: 40px;
}
