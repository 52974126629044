.uploading__file {
  background: rgba(35, 45, 66, 0.1);
}

.uploading__title {
  font-size: 14px;
  font-weight: 700;
}

.progress--color {
  background-color: rgba(41, 45, 70, 0.2) !important;
  height: 7px !important;
}
