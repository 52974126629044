.main-content {
  flex-grow: 1;
  width: 100vw;
}

.view__sub-headers {
  font-size: 12px;
  font-weight: 500;
  width: 30%;
}

.editions__title {
  font-size: 16px;
  font-weight: 500;
}
