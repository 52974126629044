.optimisation-level {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.optimisation-level-optimal-fg {
  color: var(--primary-700);
}

.btn.optimisation-level-optimal-fg,
.btn.optimisation-level-optimal-fg:hover {
  border: 1px solid var(--primary-700);
}

.optimisation-level-optimal-bg {
  background: var(--primary-100) !important;
}

.optimisation-level-suboptimal-fg {
  color: var(--gray-600);
}

.btn.optimisation-level-suboptimal-fg,
.btn.optimisation-level-suboptimal-fg:hover {
  border: 1px solid var(--gray-600);
}

.optimisation-level-suboptimal-bg {
  background: var(--gray-200) !important;
}

.optimisation-options-container {
  border: none !important; /* override the default dropdown styling */
  margin: 8px 0 0 0 !important; /* override the default dropdown styling */
  padding: 0 !important; /* override the default dropdown styling */
}
