.profile-section-panel-link:hover {
  text-decoration: none;
}

.profile-section-panel-title {
  color: #0e1e39;
}

.profile-section-panel-chevron {
  color: #596a87;
  height: 20px;
  width: 20px;
}

.profile-section-panel:hover {
  box-shadow: 0px 8px 16px rgba(51, 77, 89, 0.08),
    0px 2px 4px rgba(51, 77, 89, 0.08);
}
