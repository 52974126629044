.unsub__height {
  height: 100vh;
}

.unsub__h1 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.unsub__body-1 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
}

.unsub__card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(36, 28, 86, 0.12),
    0px 8px 14px rgba(25, 34, 68, 0.08);
  border-radius: 4px;
  max-width: 450px;
  padding: 50px;
  width: fit-content;
  height: fit-content;
}

.unsub__tick {
  color: #3976e5;
  font-size: 100px !important;
}

.unsub__card__header {
  font-size: 20px;
  font-weight: 500;
}

.unsub__card__description {
  font-size: 16px;
  font-weight: 400;
}

.unsub__card__btn {
  font-size: 16px;
  font-weight: 500;
}

.powered__text {
  font-size: 14px;
  font-weight: 400;
}
