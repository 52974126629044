.edit-subject {
  cursor: text !important;
  height: 44px;
}

.email-subject-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
