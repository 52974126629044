.confirm--checkbox {
  background: rgba(35, 45, 66, 0.1) !important;
}

.confirm__warning {
  font-size: 14px;
  font-weight: 400;
}

.upload__button {
  min-width: 280px;
}

.select-drag-zone {
  background-color: #f7f9fc;
  padding: 40px 60px;
  border: dashed 1px #dae2ec;
  border-radius: 4px;
}

.select-drag-zone.dropzone_active {
  opacity: 0.5;
}

.dnd-disabled {
  opacity: 0.65;
}

.dnd-label {
  margin-bottom: 12px;
}

.btn.select-file-btn {
  padding: 8px 12px;
}
