.preview__iframe {
  overflow: hidden;
}

.preview__iframe.desktop {
  min-width: 611px; /* Stop the desktop preview using mobile styling */
}

.second-row {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
