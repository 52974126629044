.view__header {
  font-size: 18px;
  font-weight: 500;
}

.campaigns-wrapper {
  flex-direction: column;
  gap: 10px;
}

.campaigns-wrapper-padding {
  padding-bottom: 15px;
}

.manage-subscribers .dropdown svg {
  width: 20px;
  height: 20px;
}

.manage-subscribers .dropdown-menu {
  min-width: 224px !important;
}

.manage-subscribers .dropdown-header {
  padding: 16px 16px 8px 16px;
  color: var(--gray-600);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.manage-subscribers .dropdown-divider {
  margin: 0;
}

@media (min-width: 769px) {
  .campaigns-wrapper {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .ebx-h1.campaign-name {
    margin-top: auto;
    margin-bottom: auto;
  }

  .campaigns-wrapper .ml-auto {
    margin-left: 0 !important;
  }
}
