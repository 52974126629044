.settings-panel-default {
  background: #ffffff;
}

.settings-panel-disabled {
  background: #ffffff;
}

.settings-panel-success {
  background: #d6f3e5;
}

.settings-panel-info {
  background: #e3eaf3;
}

.settings-panel-title {
  font-size: 16px;
  color: #0e1e39;
  margin-bottom: 0;
}

.settings-panel-title-disabled {
  opacity: 0.5;
  display: flex;
  align-items: center;
}

.settings-panel-description {
  margin-top: 1px;
  font-size: 14px;
}

.settings-panel-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.settings-panel-arrow-disabled {
  opacity: 0.5;
}

.settings-panel-icon-container {
  width: 36px;
}

.settings-panel-icon {
  margin-bottom: 2px;
}
