.view__last-edited {
  font-size: 12px;
  font-weight: 400;
}

.view__subjects {
  font-size: 14px;
  font-weight: 500;
}

.view__contacts,
.view__sent {
  font-size: 13px;
  font-weight: 500;
}

.delete__icon {
  cursor: pointer;
}

.editions__approval {
  height: fit-content;
}

.edition__sending {
  color: #424d60;
}

.edition__block {
  background: lightgray !important;
  opacity: 0.5;
}

.edition__buttons {
  min-width: 65px;
}

.edition__flex {
  flex: 1;
}

.edition-padding,
.edition-padding-sent {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.edition-padding {
  padding-top: 16px !important;
}

.edition-padding-sent {
  padding-top: 6px !important;
}

.edition-toggle-margin {
  margin-left: -13px;
  margin-right: 8px;
}

.edition-toggle-colour {
  color: var(--gray-600);
}

.edition-link:hover {
  text-decoration: none;
}

.edition-panel {
  padding-top: 8px;
  padding-bottom: 8px;
}

.edition-link:hover .edition-panel {
  background-color: #f7f9fc;
  box-shadow: 0 0 3px var(--gray-600);
}

.dropdown-remove {
  color: #212529 !important;
}

.dropdown-remove:hover {
  color: #16181b !important;
}

.dropdown-remove:active {
  color: #fff !important;
}

.ebx-menu-dropdown > button {
  padding: 12px 16px !important;
}

.ebx-menu-dropdown > button:first-child {
  padding-top: 16px !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ebx-menu-dropdown > button:last-child {
  padding-bottom: 16px !important;
  border-bottom: none !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.view-all-margin {
  margin-right: -13px;
}
