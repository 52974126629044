.team-list {
  padding: 32px 40px;
}

.team-settings-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--gray-900);
}

.team-settings-title-wrapper {
  margin-bottom: 26px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.team-wrapper .user-name {
  line-height: 20px;
}

.team-wrapper .user-email {
  color: var(--gray-600);
}
