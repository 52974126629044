/*
File Name: EBX UI Kit
Author: Robert Dewar-Robson / Andy Neale
Description: This file contains the global styles for different echobox components.
Notes: Please check the existing CSS classes before creating new ones. 
Keep the file organised by sorting CSS classes by their function. 
The comment headers are organised alphabetically.
The "!important" property has been used to override particular bootstrap styles.
If a CSS class is no longer being used, please remove it or add it to the "backup-styles.css" file if it could be used at a later date.
You can test your style changes on the test page: http://localhost:3000/ebx-test
Version: 1.0
*/

/* Accordions */
.ebx-accordion .card {
  border-radius: 8px;
  border-color: var(--gray-300);
}

.ebx-accordion .card-header {
  background-color: var(--white);
  border-bottom: none;
}

.ebx-accordion .card-header svg {
  width: 12px;
  height: 18px;
  position: relative;
  top: 4px;
  opacity: 70%;
  margin-right: 6px;
}

.ebx-accordion .ebx-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--gray-900);
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ebx-accordion .card-body {
  padding: 0 1.25rem 1.25rem 2.5rem;
}

.ebx-accordion .ebx-text-1 {
  margin-bottom: 1rem;
}

.ebx-accordion img + .ebx-text-1,
.ebx-accordion img + ul {
  margin-top: 1rem; /* Add space between image and following text */
}

.ebx-accordion img {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

/* Article Blocks */
.ebx-article-section {
  background: #ffffff;
  border: 1px solid #e3eaf3;
  box-sizing: border-box;
  border-radius: 4px;
}

.ebx-article-section:hover {
  box-shadow: 0px 8px 16px rgba(51, 77, 89, 0.08),
    0px 2px 4px rgba(51, 77, 89, 0.08);
}

.ebx-disabled-article {
  background: #ffffff;
  border: 1px solid #e3eaf3;
  box-sizing: border-box;
  border-radius: 4px;
}

.ebx-disabled-article-button {
  cursor: auto !important;
}

.ebx-disabled-article:focus {
  box-shadow: none !important;
}

/* Backgrounds */
.ebx-signin-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  overflow: hidden;
}

.ebx-sign-in-background {
  min-height: 100vh;
  background-size: cover;
  background-image: url(assets/img/ebx-background.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.ebx-background-colour {
  background: #f7f9fc;
}

/* Buttons */
.ebx-btn-primary {
  background: var(--primary-600);
  border: 1px solid var(--primary-700);
  border-radius: 6px;
  color: var(--white);
  white-space: nowrap;
  line-height: 20px;
}

.ebx-btn-primary:hover {
  background: var(--primary-600);
  border: 1px solid var(--primary-700);
  color: var(--white);
}

.ebx-btn-primary:disabled {
  background: var(--primary-600);
  opacity: 0.36;
  border: 1px solid var(--primary-700);
}

.ebx-btn-secondary {
  background: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  color: var(--gray-900);
  white-space: nowrap;
  line-height: 20px;
}

.ebx-btn-secondary:hover {
  background: #ffffff;
  border: 1px solid var(--gray-300);
  color: var(--gray-900);
}

.ebx-btn-secondary:focus {
  background: #ffffff;
  border: 1px solid var(--gray-300);
  color: var(--gray-900);
}

.ebx-btn-secondary:disabled {
  background: #ffffff;
  border: 1px solid var(--gray-300);
  color: var(--gray-900);
  opacity: 0.36;
}

.ebx-btn-lg {
  min-height: 44px;
  padding: 12px 16px;
}

.ebx-btn-md {
  min-height: 36px;
  padding: 8px 12px;
}

.ebx-btn-ellipsis-background {
  width: 100px;
  height: 20px;
  right: 0px;
  position: absolute;
  z-index: 2;
  background-image: -webkit-linear-gradient(
    -180deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 1) 40%,
    rgba(127, 127, 127, 0) 100%
  );
  background-image: -moz-linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 1) 40%,
    rgba(127, 127, 127, 0) 100%
  );
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 1) 40%,
    rgba(127, 127, 127, 0) 100%
  );
}

.ebx-btn-ellipsis {
  background-color: #ffffff;
  width: 35px;
  height: 20px;
  right: 10px;
  top: 15px;
  z-index: 3;
}

.ebx-btn-link:focus {
  box-shadow: none !important;
}

.ebx-btn-link:active {
  box-shadow: none !important;
}

/* Cards */
.ebx-card {
  background: #ffffff;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  max-width: 440px;
  padding: 30px;
  width: 100%;
}

@media (min-width: 460px) {
  .ebx-card {
    padding: 50px;
  }
}

/* Colours */
.ebx-link {
  color: var(--echobox_blue);
}

.ebx-link:hover {
  text-decoration: none !important;
  color: #0056b3;
}

.ebx-hr-text-colour {
  color: #737b8b;
}

.ebx-a {
  color: var(--echobox_blue);
}

.ebx-a:hover {
  color: var(--gray-600);
  text-decoration: none;
}

.ebx-body-colour {
  color: #424d60;
}

.ebx-faded-colour {
  color: var(--gray-600);
}

.ebx-edition-subject--color {
  color: #0e1e39;
}

.ebx-faded-link {
  color: var(--gray-600);
}

.ebx-faded-link:hover,
.ebx-faded-link:focus {
  color: var(--gray-600);
  text-decoration: none;
}

.ebx-warning-colour {
  color: #ff7759;
}

.ebx-late-colour {
  color: rgba(82, 103, 129, 0.6);
}

.ebx-late-colour > path {
  fill: rgba(82, 103, 129, 0.6) !important;
}

.ebx-info-colour {
  color: #1c5fd8;
}

.ebx-title-colour {
  color: var(--gray-900);
}

.ebx-subtitle-colour {
  color: var(--gray-600);
}

.ebx-card-colour {
  background-color: #ffffff;
}

.ebx-disabled-text {
  opacity: 0.36;
}

/* Containers */
.ebx-curation-subheader {
  background: #ffffff;
  border: 1px solid #dae2ec;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
}

/* Cursor */
.ebx-cursor {
  cursor: pointer !important;
}

/* Dividers */
.ebx-error-hr {
  border-top: 1px solid #cd3d64 !important;
}

.ebx-hr {
  border-top: 1px solid #bfd0df;
}

/* Dropdowns */
.ebx-dropdown-options {
  background: #ffffff !important;
  border: 1px solid var(--gray-300);
}

.ebx-dropdown-options > a {
  color: #424d60 !important;
}

.ebx-dropdown-options > a:hover {
  background: #e3eaf3 !important;
}

.ebx-dropdown-options > a.active {
  background: #e3eaf3 !important;
  color: var(--echobox_blue) !important;
}

/* Fonts */
.ebx-roboto {
  color: #424d60;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ebx-h1 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.ebx-h2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ebx-h3 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ebx-h4 {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 130% !important;
}

.ebx-h5 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.ebx-body-1 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.ebx-body-2 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
}

.ebx-hr-text {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
}

.ebx-small-text {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
}

.ebx-bold-small-text {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.ebx-h1-normal {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.ebx-input-box-font {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.ebx-strikeout {
  text-decoration-line: line-through;
}

.ebx-no-underline {
  text-decoration: none !important;
}

/* Globals */
:root {
  --echobox_blue: #3976e5;
  --green-600: #0d7757;
  --red-600: #b91e43;
  --primary-100: #e4ecfb;
  --primary-600: #3371e6;
  --primary-700: #2962db;
  --white: #ffffff;
  --gray-100: #f7f9fc;
  --gray-200: #ebeef5;
  --gray-300: #dbe3f0;
  --gray-500: #909db5;
  --gray-600: #596a87;
  --gray-900: #0e1e39;

  /*Layout global variables*/
  --structure-width: 600px;
}

/* Headers */
.ebx-header {
  background: #f7f9fc;
  box-shadow: inset 0px -1px 0px #dbe3f0;
  z-index: 2;
}

.ebx-header-padding {
  padding-right: 40px !important;
}

.ebx-header-left {
  min-width: 0;
}

@media (min-width: 769px) {
  .ebx-header-left {
    min-width: 275px;
  }
}

/* Help Boxes */
.ebx-help-box {
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 24px;
}

.ebx-help-box-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--gray-900);
  margin-bottom: 0.5rem;
}

.ebx-help-box-description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--gray-600);
  margin-bottom: 0.5rem;
}

/* Icons */
.ebx-pw-visible-icon {
  color: #424d60;
  margin-bottom: 5px;
  transform: scaleX(-1);
  width: 20px !important;
}

.ebx-warning-icon {
  height: 18px !important;
  width: 18px !important;
}

.ebx-info-icon {
  width: 12px !important;
  height: 12px !important;
}

.ebx-upload-icon {
  fill: none !important;
}

.ebx-status-icon {
  font-size: 1rem !important;
}

.ebx-download-icon {
  height: 9px;
  width: 8px;
}

.ebx-error-icon {
  width: 16.67px;
  height: 16.67px;
}

.ebx-display-info-icon {
  width: 16.67px;
  height: 16.67px;
}

.ebx-general-icon-size {
  width: 20px !important;
  height: auto !important;
}

/* Inputs */
.ebx-input {
  background: #fff;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  box-sizing: border-box;
  height: auto;
  line-height: normal !important;
  padding: 12px;
  color: #0e1e39;
}

.ebx-input:focus {
  color: #0e1e39;
}

.ebx-static-input {
  background: #ffffff;
  border: none !important;
  box-shadow: none !important;
  color: var(--gray-900) !important;
  height: auto;
  line-height: normal !important;
  padding: 12px 16px;
}

.ebx-static-input:focus-within {
  box-shadow: 0 0 0 0.2rem rgb(196 214 247) !important;
  border-radius: 2px !important;
  color: var(--gray-900) !important;
}

.ebx-input-box {
  background: #ffffff;
  border: none !important;
  box-shadow: none !important;
}

.ebx-input-border {
  box-shadow: 0px 0px 2px rgba(53, 67, 86, 0.4),
    0px 1px 2px rgba(53, 67, 86, 0.45);
  border-radius: 2px !important;
}

.ebx-input-border:focus-within {
  box-shadow: 0 0 0 0.2rem rgb(196 214 247) !important;
  border-radius: 2px !important;
}

.ebx-input-error {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b91e43;
  box-shadow: 0px 0px 0px 4px rgba(185, 30, 67, 0.24);
}

.ebx-input-textarea {
  resize: none !important;
}

.ebx-expandable-textarea {
  min-height: 41px;
  padding: 10px 12px;
}

.ebx-select__control {
  min-height: 42px !important;
}

.ebx-select__indicators {
  margin-right: 15px;
}

.ebx-select__indicator-separator {
  display: none !important;
}

.ebx-select__menu {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: 0 !important;
}

.ebx-settings-dropdown {
  border-radius: 4px;
  height: 44px;
  border: 1px solid #e3eaf3 !important;
  background: #ffffff !important;
  box-sizing: border-box;
  color: #424d60 !important;
  display: flex !important;
  align-items: center;
  transition: none !important;
}

.ebx-settings-dropdown::after {
  border: 0 !important;
}

.ebx-settings-dropdown:focus {
  border: 1px solid #dbe3f0;
  box-shadow: 0px 0px 0px 4px rgba(51, 113, 230, 0.4);
}

.ebx-settings-dropdown:active {
  border: 1px solid #dbe3f0;
  box-shadow: 0px 0px 0px 4px rgba(51, 113, 230, 0.4);
}

.ebx-settings-dropdown .placeholder {
  color: #3c4257;
  opacity: 0.5;
  font-weight: normal;
}

.ebx-settings-dropdown > svg {
  position: absolute;
  right: 1rem;
}

.ebx-settings-dropdown.active {
  border-bottom: 0px !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ebx-settings-dropdown.active + div {
  box-shadow: none;
  border: 1px solid #e3eaf3 !important;
  border-top: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: block !important;
  margin-top: 0;
  opacity: 1 !important;
  pointer-events: unset !important;
}

.ebx-settings-dropdown-options .dropdown-item {
  font-weight: normal !important;
  padding: 6px 16px;
}

.ebx-settings-dropdown-options .dropdown-item:hover,
.ebx-settings-dropdown-options .dropdown-item:focus {
  background-color: var(--echobox_blue);
  color: white;
}

.ebx-settings-input {
  border: 1px solid #e3eaf3 !important;
  border-radius: 4px;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: normal !important;
}

/* Input Display Boxes */
.ebx-email-subject {
  background: #ffffff;
  border: 1px solid #e3eaf3;
  box-sizing: border-box;
  border-radius: 4px;
}

.ebx-email-subject-active:hover {
  box-shadow: 0px 8px 16px rgb(51 77 89 / 8%), 0px 2px 4px rgb(51 77 89 / 8%);
}

.ebx-display-box {
  background: #ffffff;
  border: 1px solid #dbe3f0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 44px;
}

.ebx-error-display-box {
  background: #fff2f7;
  border: 1px solid #b91e43;
  box-sizing: border-box;
  border-radius: 4px;
  height: 52px;
}

.ebx-info-display-box {
  background: #f7f9fc;
  border: 1px solid #3976e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
}

/* Labels */
.ebx-label-input {
  margin-bottom: 8px !important;
  line-height: normal !important;
}

.ebx-label-info {
  background: var(--primary-100);
  color: #2962db;
  border-radius: 2px;
  border-radius: 16px;
  width: fit-content;
}

.ebx-label-base {
  background: #ebeef5;
  color: #596a87;
  border-radius: 2px;
  border-radius: 16px;
  width: fit-content;
}

.ebx-label-new {
  background: #e3eaf3;
  color: var(--gray-600);
  border-radius: 2px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-send {
  background: #e3eaf3;
  color: var(--gray-600);
  border-radius: 2px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-sent {
  background: #e3eaf3;
  color: var(--gray-600);
  border-radius: 2px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-sending {
  background: #e3eaf3;
  color: var(--gray-600);
  border-radius: 2px;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-pending {
  background: #ffecd6;
  border-radius: 2px;
  color: #9a4f1d;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}

.ebx-label-pending-generation {
  background: var(--primary-600);
  border-radius: 2px;
  color: var(--white);
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}

.ebx-label-approved {
  background: #d6f3e5;
  border-radius: 2px;
  color: #32674d;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-active {
  background: #d6f3e5;
  border-radius: 2px;
  color: #32674d;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-automation {
  background: var(--primary-100);
  border-radius: 2px;
  color: var(--primary-700);
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-disabled {
  background: var(--gray-200);
  border-radius: 2px;
  color: #596a87;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-error {
  background: #fce3ec;
  border-radius: 2px;
  color: #b91e43;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-missed {
  background: var(--gray-200);
  border-radius: 2px;
  color: #596a87;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-populating {
  background: #ffecd6;
  border-radius: 2px;
  color: #9a4f1d;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-recommended {
  background: var(--primary-600);
  color: #2962db;
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-label-optional {
  background: #ebeef5;
  color: #596a87;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-staff-mode-label {
  background: #f4e3fc;
  border-radius: 2px;
  color: #881eb9;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.ebx-developer-mode-label {
  background: #881eb9;
  border-radius: 2px;
  color: #fefefe;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

/* Layout */
.ebx-h-100 {
  height: 100%;
}

.ebx-h-vh-100 {
  height: 100vh;
}

/* Logos */
.ebx-logo {
  height: auto;
  margin: 80px auto 40px;
  width: 84px;
}

/* Margins & Padding */
.ebx-test-page-margins {
  margin: 0px 5%;
}

.ebx-header-right {
  line-height: normal !important;
  padding-top: 5px;
}

.ebx-no-editions {
  margin-top: 50px;
}

.ebx-sub-panel {
  padding: 0px 20px;
}

/* Menu Options */
.ebx-menu {
  background: none;
  /* Needed for scrollbar. See https://stackoverflow.com/a/66689926/6640093 */
  min-height: 0;
}

.ebx-menu-option {
  color: var(--gray-600) !important;
  border-left: solid 3px transparent !important;
}

.ebx-menu-option:hover {
  color: #424d60 !important;
  border-left: solid 3px var(--echobox_blue) !important;
}

.ebx-menu-option.active {
  color: var(--echobox_blue) !important;
  border-left: solid 3px var(--echobox_blue) !important;
}

.ebx-menu-option.active:hover {
  color: var(--echobox_blue) !important;
  border-left: solid 3px var(--echobox_blue) !important;
}

/* Modals */
.ebx-modal-header {
  padding: 16px 20px;
}

.ebx-modal-header .ebx-modal-close {
  color: var(--gray-500);
}

.ebx-modal-body {
  padding: 15px 20px;
}

.ebx-modal-footer {
  /* Designs have vertical padding as 16px, but ignores the button's border (1px)
      Therefore, to match the designs, we use a vertical padding of 15px */
  padding: 15px 20px;
}

.ebx-default-modal-body {
  background: #f7f9fc;
}

.ebx-warning-modal-body {
  background: #f7f9fc;
}

.ebx-error-modal-body {
  background: #f5d8e0;
}

.ebx-upload-warning {
  border-top: 1px solid #e3eaf3;
}

.ebx-upload-body {
  padding: 24px 20px;
}

.ebx-upload-file {
  background: #ffffff;
  border: 1px solid #bfd0df;
  box-sizing: border-box;
  border-radius: 4px !important;
}

/* Notifications */
.ebx-status-notification {
  background: #ffffff;
  border: 1px solid #bfd0df;
  box-sizing: border-box;
  border-radius: 4px;
}

.ebx-status-notification-card {
  max-width: 450px;
  width: 100%;
}

/* Overflow */
.ebx-text-overflow {
  /* TODO: Add white-space nowrap to this but fix bug with error component causing the whole page to elongate */
  overflow: hidden;
  text-overflow: clip;
}

.ebx-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ebx-whitescape-nowrap {
  white-space: nowrap;
}

/* Panels */
.ebx-panel {
  border: 1px solid var(--gray-300);
  box-sizing: border-box;
  border-radius: 8px;
}

.ebx-panel-header {
  min-height: 48px;
  color: #424d60;
}

.ebx-panel-tag-pending {
  background: #f5d8e0;
  border-radius: 3px 0px 0px 3px;
  width: 12px;
}

.ebx-panel-pending {
  background: #ffffff;
}

.ebx-panel-tag-approved {
  background: #d6f3e5;
  border-radius: 3px 0px 0px 3px;
  width: 12px;
}

.ebx-panel-approved {
  background: #ffffff;
}

.ebx-panel-tag-sending {
  background: #bfd0df;
  border-radius: 3px 0px 0px 3px;
  width: 12px;
}

.ebx-panel-sending {
  background: #f7f9fc;
}

.ebx-panel-tag-error {
  background: #cd3d64;
  border-radius: 3px 0px 0px 3px;
  width: 12px;
}

.ebx-panel-error {
  background: #ffffff;
  border: 1px solid #cd3d64;
}

.ebx-panel-missed {
  background: #f7f9fc;
  border: 1px solid var(--gray-300);
}

.ebx-panel-missed .ebx-h2 {
  color: #0e1e39;
  font-weight: 500;
}

.ebx-panel-missed .ebx-h3 {
  color: #596a87;
  font-weight: 500;
}

.ebx-sub-info {
  border: 1px solid #bfd0df;
  box-sizing: border-box;
  border-radius: 4px;
}

.ebx-sub-info-warning {
  background: #fff7ee;
  min-height: 105px;
}

.ebx-sub-info-processing {
  background: #ffffff;
  min-height: 155px;
}

.ebx-sub-info-default {
  background: #ffffff;
  min-height: 155px;
}

.ebx-sub-info-section {
  border-right: 1px solid #bfd0df;
}

/* Popovers & Tooltips  */
.ebx-popover-width {
  max-width: 277px;
}

/* Profile Menu */
.ebx-profile {
  background: #f7f9fc !important;
  border: 0px;
}

.ebx-profile:focus {
  border-radius: 2px;
}

.ebx-properties__background:focus {
  background: #e3eaf3 !important;
}

.ebx-profile-menu {
  background: #ffffff;
  border: 1px solid var(--gray-300);
}

.ebx-profile-text {
  color: #292d46;
}

.ebx-profile-option {
  background: #ffffff;
  color: #292d46 !important;
}

.ebx-profile-option:hover {
  background: #e3eaf3 !important;
}

.ebx-profile-option:focus {
  background: #e3eaf3 !important;
}

.ebx-profile-signout {
  transform: scaleX(-1);
  color: #424d60;
}

.ebx-profile-dropdown-arrow::after {
  color: #424d60;
  margin-right: 5px;
}

.ebx-profile-dropdown-arrow:focus {
  box-shadow: none !important;
}

.ebx-profile-username {
  color: #424d60;
  line-height: normal !important;
}

.ebx-profile-email {
  color: var(--gray-600);
  line-height: normal !important;
}

.ebx-profile-initials {
  background: #e3eaf3;
  border-radius: 16px;
  color: #424d60;
  height: 32px;
  padding: 7px;
  width: 32px;
}

/* Progress Bars */
.ebx-progress-bar {
  background-color: #bfd0df !important;
  height: 7px !important;
}

.ebx-progress-bar > div.progress-bar {
  background-color: var(--echobox_blue) !important;
}

.ebx-result-icon {
  height: auto !important;
  width: 40px !important;
}

.ebx-error-colour {
  color: #b91e43;
}

.ebx-blue {
  color: var(--echobox_blue);
}

/* Menu Dropdowns */
.ebx-menu-dropdown {
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  padding: 0;
}

.ebx-menu-dropdown > a {
  padding: 12px 16px;
}

.ebx-menu-dropdown > a:first-child {
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ebx-menu-dropdown > a:last-child {
  padding-bottom: 16px;
  border-bottom: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.modal-backdrop {
  background-color: var(--gray-600);
}

.modal-backdrop.show {
  opacity: 0.2;
}

/* Tabs */
.nav-tabs .nav-link.active {
  border-color: none !important;
  border: none !important;
  border-bottom: 3px solid #3371e6 !important;
  background: none !important;
}

.nav-link:hover {
  border-color: none !important;
  border: none !important;
  background: none !important;
  border-right: solid 3px transparent !important; /* counteracts losing the left border on hover */
}

.nav-tabs .nav-link {
  border-color: none !important;
  border: none !important;
  background: none !important;
}
