.campaign-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.campaign-list .header {
  margin-bottom: 24px;
}

.campaign-list .title {
  color: #0e1e39;
  font-size: 24px;
  font-weight: 500;
}

.campaign-list .campaign {
  background: #ffffff;
  border: 1px solid var(--gray-300) !important;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #0e1e39;
  padding: 24px;
}

.campaign-list .campaign-under-setup-icon {
  color: var(--gray-600);
}

.link-unstyled {
  color: unset;
}

.link-unstyled:hover,
.link-unstyled:focus {
  color: unset;
  text-decoration: none;
}

.link-unstyled[disabled] {
  pointer-events: none;
}

.modal-dialog.new-campaign-modal {
  max-width: 460px;
}

.new-campaign-modal .modal-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.new-campaign-modal .new-campaign-close {
  color: #909db5;
}

.new-campaign-modal .label,
.new-campaign-modal .modal-title {
  color: #0e1e39;
}

.new-campaign-modal .campaign-name-input {
  height: 36px !important;
  border-radius: 6px;
}

.new-campaign-modal .modal-body {
  padding: 20px;
}

.new-campaign-modal .new-campaign-footer {
  padding: 16px 20px;
}

.new-campaign-modal .success-check {
  margin-right: 10px;
  color: var(--green-600);
}
