.invite-user-description {
  color: #596a87;
}

.add-user-plus-wrapper {
  height: 16px;
  width: 16px;
}

.add-user-plus {
  height: 9.33px;
  width: 9.33px;
}

.modal-dialog.invite-user-modal {
  max-width: 460px;
}

.invite-user-modal .modal-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--gray-900);
}

.invite-user-modal .invite-user-input {
  box-shadow: none;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
}

.invite-user-modal .ebx-input-error {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b91e43;
  box-shadow: 0px 0px 0px 4px rgba(185, 30, 67, 0.24);
}
