.modal__title {
  color: #424d60;
}

.model-close {
  opacity: 0.5;
}

.model-close:hover {
  opacity: 1;
}

button.model-close:hover {
  color: var(--gray-600);
}
