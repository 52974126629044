:root {
  --address-width: 600px;
}

.external-link-icon {
  width: 16px !important;
  color: #737b8b;
}

.copy-icon {
  width: 16px !important;
}

.copy--button {
  min-height: 36px !important;
  padding: 8px 12px !important;
}

.copied__popover {
  background: #ffffff;
  border: 1px solid var(--gray-300);
  border-radius: 4px !important;
  font-family: 'Roboto', sans-serif !important;
}
.copied__popover > .arrow {
  display: none !important;
}

.subscription__address--input {
  height: 36px !important;
}

.subscription--display {
  background: #ffffff;
  border: 1px solid #e3eaf3;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0e1e39;
  min-height: 36px !important;
  max-width: var(--address-width);
  width: 100%;
  overflow: hidden;
}

.subscription--display-large {
  max-width: calc(var(--address-width) * 2);
}

.subscription--display pre {
  margin-bottom: 0;
}

.subscription--display span {
  /* position: absolute; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow.subscription--display:hover span {
  transform: translateX(0);
  transition: 5s;
  overflow: visible;
  transform: translateX(calc((var(--address-width) - 60px) - 100%));
}

.overflow.subscription--display:hover .external-link-icon {
  display: none;
}

.subscription--display:hover {
  text-decoration: none !important;
  color: #0e1e39;
}

.subscription-description {
  color: #596a87;
}

.subscription-sections {
  margin-bottom: 64px;
}

.subscription-page--padding {
  margin: 32px 40px;
}
