.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
}

.curation__sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.curation__subheader {
  height: 75px;
}

.curation__content {
  min-height: 300px;
  background: #ffffff;
  box-sizing: border-box;
  flex-grow: 1;
}

.curation__footer {
  height: 25px;
}

.f-container {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  /*ie10*/
  display: -ms-flexbox;
  -ms-flex-direction: column;
}

.main {
  overflow-y: auto;
}

.progress__height {
  height: 100vh;
}

.error__border {
  border: 1px solid #cd3d64;
}

/* Desktop View Changes */
@media (min-width: 380px) {
  #main-content {
    margin-bottom: 0;
    margin-top: var(--main-content__margin-top);
  }
}

/* Desktop View Changes */
@media (min-width: 769px) {
  #main-content {
    flex-wrap: nowrap;
    margin-bottom: 0;
    margin-top: var(--main-content__margin-top);
    height: calc(100% - var(--main-content__margin-top));
  }
}
