.campaign-details--input {
  background: #ffffff;
  border: 1px solid #e3eaf3;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 44px;
  max-width: 450px;
  width: 100%;
}

.settings__header {
  font-size: 18px;
  font-weight: 500;
  opacity: 0.5;
  color: #0e1e39;
}

.settings__labels {
  color: var(--gray-900);
  opacity: 0.5;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}

.campaign-details-settings .form-control:disabled {
  background: white !important;
  border: 1px solid #dbe3f0 !important;
  border-radius: 6px !important;
}

.campaign-details--help-text {
  color: #596a87;
  font-size: 14px;
  opacity: 0.5;
}

.campaign-details__change-label {
  color: #596a87;
}
