.dropdown-menu {
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-item {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--gray-900);
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdown-divider {
  margin: 0;
}
