.settings-wrapper {
  width: 100%;
}

.settings--input--sm {
  min-height: 36px;
  height: 36px !important;
}

.main-content--padding {
  padding: 1.5rem;
}

/* Desktop View Changes */
@media (min-width: 769px) {
  .main-content--padding {
    padding: 34px 40px;
  }
}
