.form-wrapper {
  max-width: 420px;
}

.profile-title-wrapper {
  margin-bottom: 26px;
}

.profile-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #0e1e39;
}

.profile-description {
  color: #596a87;
  margin-bottom: 32px;
}

.form-group.profile-settings-form-group-valid {
  margin-bottom: 20px;
}
