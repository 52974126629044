.main-content {
  flex-grow: 1;
  width: 100%;
}

.curation__scroll {
  overflow-y: visible;
  height: fit-content;
}

.curation-titles__sections {
  min-height: var(--curation__sections__titles__height);
  height: fit-content;
}

.curation-titles__headers {
  font-size: 16px;
  font-weight: 700;
}

.curation-titles__description {
  font-size: 14px;
  font-weight: 400;
}

.property-headers {
  font-weight: 500;
}

.property-details-headers {
  font-size: 15px;
  font-weight: 700;
}

.property-details {
  font-size: 14px;
  font-weight: 400;
}

.info-box {
  background: #f4f5f6;
  height: 100%;
  min-height: 51px;
}

#info-icon {
  height: 20px;
  width: auto;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #e3eaf3;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: #e3eaf3;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #8994ae;
  border-radius: 10px;
  width: 10px;
}

.preview__section {
  border-top: 2px solid #bfd0df;
  margin-top: 15px;
}

.curation__loading {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--main-content__margin-top) - 15px);
  min-height: calc(100vh - var(--main-content__margin-top) - 15px);
  margin-top: calc(30px + (var(--main-content__margin-top)));
}

/* Desktop View Changes */
@media (min-width: 380px) {
  .curation__loading {
    margin-top: calc(var(--main-content__margin-top));
  }
}

/* Desktop View */
@media (min-width: 992px) {
  .preview__section {
    border-top: none;
  }

  .curation__scroll {
    overflow-y: scroll;
    height: calc(
      100vh -
        (
          var(--header__height) + var(--subheader__height) +
            var(--footer__height) + var(--curation__sections__titles__height)
        )
    );
  }
}
