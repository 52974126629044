#subheader {
  /* background: #FFFFFF; */
  z-index: 2;
}

.edition__name {
  font-size: 20px;
  font-weight: 500;
}

.edition__approval {
  background: rgba(35, 45, 66, 0.2);
  font-size: 12px;
  font-weight: 400;
  max-width: 200px;
}

.campaign__name {
  font-size: 20px;
  font-weight: 400;
}

.navbar--height {
  height: auto;
  min-height: var(--subheader__height);
}

.nav--buttons {
  align-items: flex-start;
}

.account__icon svg {
  height: 200px;
  width: 200px;
}

/* TODO - remove when happy that everything global-info-related is working */
.global-info-state {
  background-color: #eee;
  border: 1px solid #666;
  border-radius: 2px;
  font-size: 13px;
  padding: 3px 5px;
}

.profile__settings {
  color: #292d46;
  font-size: 14px;
  font-weight: 500;
}

.subheader-close {
  border-color: #dae2ec;
}

.subheader-campaign-name {
  margin-left: 20px;
}

#approve-toggle.dropdown-toggle::after {
  content: none;
}

/* Desktop View */
@media (min-width: 993px) {
  .nav--buttons {
    align-items: center;
  }
}
