/* Calendar Properties */
.date-picker-container {
  width: 152px;
}

.react-datepicker {
  border: 1px solid var(--gray-300);
  padding: 4px;
}

.react-datepicker-popper[data-placement^='bottom'],
.react-datepicker-popper[data-placement^='top'] {
  padding: 4px 0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: white;
  border: none;
}

.react-datepicker__navigation,
.react-datepicker__navigation--next {
  margin: 12px 0px;
}

.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name {
  font-family: 'Roboto', sans-serif !important;
}

.react-datepicker__current-month {
  padding: 8px 0px;
  color: var(--gray-900);
  font-size: 14px;
  font-weight: 500 !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  font-size: 13px;
  font-weight: 300 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-600);
}

.react-datepicker__day--selected:hover {
  background-color: var(--primary-700);
}

.react-datepicker__day--outside-month {
  color: #e0e0e0 !important;
  pointer-events: none;
}

/* Time Dropdown Properties */
.react-datepicker-popper {
  width: 115px;
}

.react-datepicker--time-only,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker--time-only {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.react-datepicker-time__header,
.react-datepicker__time-list-item {
  font-family: 'Roboto', sans-serif !important;
}

.react-datepicker-time__header {
  color: var(--gray-900);
  font-size: 14px;
  font-weight: 500 !important;
}

.react-datepicker__time-box {
  margin: 0px !important;
}

.react-datepicker__time-list-item {
  padding: 5px 0px !important;
  font-size: 13px;
  font-weight: 400 !important;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--primary-600) !important;
}
