.modal-dialog.verify-email-modal {
  max-width: 620px;
}

.verify-email-modal .verify-email-close {
  color: #909db5;
}

.verify-email-modal .verify-email-input {
  border: 1px solid #dbe3f0;
  border-radius: 6px;
  box-shadow: none;
  color: #0e1e39;
  max-width: 285px;
}

.verify-email-modal .verify-email-input::placeholder {
  color: #0e1e39;
  opacity: 0.4;
}

.verify-email-modal .verify-email-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.verify-email-address {
  font-weight: 700;
}

.verify-email-description {
  margin-bottom: 28px;
}

.verify-email-form-group {
  color: #0e1e39;
}

.verify-email-modal .modal-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
