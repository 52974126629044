.settings-schedule .dropdown-container {
  max-width: 475px;
}

.settings-schedule .form-check {
  margin-bottom: 10px;
}

.settings-schedule
  .send-times
  .form-check-input:checked
  + label
  > div:not(.optimal-message),
.settings-schedule .approve-editions .form-check-input:checked + label + div {
  font-weight: 500;
  display: block;
}

.settings-schedule
  .send-times
  .form-check-input:checked
  + label
  > div.optimal-message {
  color: #596a87;
  display: inline-block;
}

.settings-schedule .optimal-message {
  display: none;
}

.settings-schedule .time-slot-container,
.settings-schedule .approve-editions-container {
  display: none;
  margin-top: 7px;
}

.time-slot-container {
  width: 400px;
}

.settings-schedule .time-slot-message,
.settings-schedule .approve-editions-message {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}

.settings-schedule .approve-editions-message {
  margin-bottom: 20px;
}

.settings-schedule .approve-editions-secondary-message {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
}

.settings-schedule .label-recommended {
  background: #e4ecfb;
  color: #2962db;
  border-radius: 2px;
  border-radius: 16px;
  width: fit-content;
}

.settings-schedule .monthly {
  margin-left:20px;
}

.MuiSlider-root {
  min-width: 280px;
}
