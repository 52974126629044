.settings-description {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 14px;
}

.settings-title {
  font-size: 20px;
  font-weight: 500;
  color: #0e1e39 !important;
  margin-bottom: 12px;
}

.settings-divider {
  color: #e3eaf3;
  margin-bottom: 30px;
}

.settings-divider + .settings-container {
  padding-top: 0;
}

.settings-container {
  padding-bottom: 64px;
}

.settings-radio-label {
  font-size: 14px;
  font-weight: 500;
}

.settings-drag-zone {
  max-width: 600px;
}

.settings-drag-zone p {
  margin-bottom: 12px;
}

.settings-sidebar {
  overflow: auto;
  position: relative !important;
  width: inherit;
  max-width: inherit;
  padding: 25px 30px 25px 15px !important;
  border-right: solid 1px #e3eaf3;
  height: fit-content;
}

.settings-content {
  padding: 25px 30px !important;
}

.settings-back-arrow {
  width: 17px !important;
  height: auto !important;
}

/* Desktop View */
@media (min-width: 768px) {
  .settings-sidebar {
    position: fixed !important;
    width: inherit;
    max-width: inherit;
    height: calc(100vh - 55px);
    padding: 25px 30px 25px 15px !important;
  }
  .settings-content {
    padding: 25px 40px !important;
  }
}
