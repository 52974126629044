.frequency-monthly .dropdown-occurrence-container {
  min-width: 90px;
}

.frequency-monthly .dropdown-day-container {
  min-width: 110px;
}

.frequency-monthly .monthly-editions-message {
  font-size: 14px;
  font-weight: normal;
}
