.modal-dialog.add-image-modal {
  max-width: 620px;
}

.add-image-modal .modal-body {
  padding: 24px 20px 16px;
}

.add-image-modal .add-image-description {
  color: var(--gray-600);
}
