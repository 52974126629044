.impersonate__modal {
  border: 1px solid #000000;
}

.staff-mode__box {
  width: fit-content;
}

#impersonate__title {
  font-size: 16px;
  font-weight: 700;
}

#impersonate__input {
  font-size: 13px;
  font-weight: 400;
}

.staff-mode__checkbox {
  cursor: pointer;
}

.staff-mode__checkbox::before {
  cursor: pointer;
}

.staff-mode__checkbox label {
  cursor: pointer;
}

.staff-mode__checkbox input {
  cursor: pointer;
}
