.overlay-card__header {
  align-items: center !important;
}

.overlay-card__title {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
}

.overlay-card__body {
  padding: 0 !important;
}

.info__container {
  background: #f4f5f6;
  height: 100%;
  min-height: 51px;
}

#edit-info-icon {
  height: 22px;
  width: auto;
}

.info__container--font {
  font-size: 14px;
  font-weight: 400;
}

.form__inputs--font {
  font-size: 14px;
}

.rdw-link-modal {
  border: 1px solid var(--gray-300) !important;
  border-radius: 6px !important;
  height: auto !important;
  width: 320px !important;
  box-shadow: var(--chakra-shadows-lg) !important;
  top: -20px !important;
  left: 45px !important;
}

.rdw-link-wrapper {
  margin-bottom: 0px !important;
}

.rdw-link-modal-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}

.rdw-link-modal-target-option {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.rdw-link-modal-btn {
  display: inline-flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: var(--chakra-lineHeights-5);
  font-weight: var(--chakra-fontWeights-medium);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-common);
  height: fit-content;
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  filter: drop-shadow(0px 1px 2px rgba(14, 30, 57, 0.12));
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  /* override default styles */
  width: auto !important;
  height: auto !important;
  border: var(--chakra-borders-none) !important;
  border-radius: var(--chakra-radii-md) !important;
}

.rdw-link-modal-btn:first-of-type {
  background: var(--chakra-colors-primary-600) !important;
  color: var(--chakra-colors-base);
  box-shadow: var(--chakra-shadows-borderPrimary);
}

.rdw-link-modal-btn:last-of-type {
  background: var(--chakra-colors-base) !important;
  color: var(--chakra-colors-gray-900);
  box-shadow: var(--chakra-shadows-border);
}

.rdw-link-modal-btn:first-of-type:hover {
  box-shadow: var(--chakra-shadows-borderPrimary) !important;
}

.rdw-link-modal-btn:last-of-type:hover {
  box-shadow: var(--chakra-shadows-border) !important;
}

.rdw-link-modal-btn:first-of-type:disabled {
  opacity: 0.36;
}

.rdw-link-modal-btn:last-of-type:disabled {
  opacity: 0.36;
}

.rdw-link-modal-input {
  background: #fff;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  box-sizing: border-box;
  height: auto !important;
  line-height: normal !important;
  padding: 12px !important;
}

.rdw-link-modal-target-option {
  display: none;
}

.wysiwyg-editor {
  border: 1px solid var(--gray-300);
  background: white;
  padding: 0 5px;
  border-radius: 4px;
  margin-bottom: 5px;
  min-height: 60px;
  max-height: 50vh;
  height: 100%;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
  border: none;
  border-radius: none;
  padding: 0;
  margin-bottom: 16px;
}

.rdw-inline-wrapper,
.rdw-link-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper {
  margin-bottom: 0 !important;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  box-sizing: border-box;
}

.rdw-inline-wrapper + .rdw-link-wrapper,
.rdw-link-wrapper + .rdw-list-wrapper,
.rdw-list-wrapper + .rdw-text-align-wrapper {
  margin-left: 12px;
}

.rdw-option-wrapper {
  border: none !important;
  border-right: 1px solid var(--gray-300) !important;
  margin: 0 !important;
  height: unset !important;
  padding: 10px !important;
  min-width: 36px !important;
  border-radius: 0 !important;
}

.rdw-option-wrapper:first-child {
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

.rdw-option-wrapper:last-child {
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.rdw-option-wrapper:last-child {
  border-right: none !important;
}

.rdw-option-wrapper:hover,
.rdw-option-active {
  box-shadow: none !important;
  background-color: #eee !important;
}

.rdw-option-wrapper > img {
  height: 16px !important;
  width: 16px !important;
}

img.rdw-link-decorator-icon {
  opacity: 0;
  left: 0%;
  width: 100%;
  height: 100%;
}

.rdw-link-decorator-wrapper a:hover {
  color: #007bff;
  text-decoration: none;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}

/* Desktop View */
@media (min-width: 577px) {
  .wysiwyg-editor {
    min-height: 160px;
    max-height: 400px;
    padding-left: 16px;
  }
}
