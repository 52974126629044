.sidebar__scroll {
  overflow: scroll;
}

.sidebar__campaign-name {
  padding: 6px 0px;
}

.sidebar__links:hover {
  background: #c2c2c2;
}

.sidebar__link-active {
  background: #e9eaec;
}

.sidebar__padding {
  padding-left: 1.5rem !important;
}

.sidebar__toggle {
  background: #ffffff !important;
}

.sidebar-link:hover {
  text-decoration: none;
}

.sidebar-link .sidebar-option:hover {
  color: var(--echobox_blue);
}

.sidebar__link__pl {
  padding-left: 18px;
}

.sidebar__link__pl.under-setup {
  color: #737b8b;
}

.sidebar__create-campaign {
  border-radius: 7px !important;
  border-color: #e9eaec !important;
  background: #f7f9fc !important;
  color: var(--gray-600) !important;
}

.sidebar__create-campaign:hover {
  background: #e3eaf3 !important;
  border-color: #0062cc !important;
}

.sidebar__create-campaign:focus {
  box-shadow: none !important;
}

.hr__margins {
  margin: 0.5em 0;
}

.sidebar .settings-link:hover {
  text-decoration: none;
}

/* Desktop View Changes */
@media (min-width: 769px) {

  .sidebar::-webkit-scrollbar {
    width: 0px;
  }
  .sidebar:hover::-webkit-scrollbar {
    width: 10px;
  }

  .sidebar__padding {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}
