.url-input-group .url-input {
  box-shadow: none;
  border: solid 1px #e3eaf3;
}

.url-input-group .url-input.url-input-with-append {
  border-right: 0;
}

.input-group-append {
  border: solid 1px #e3eaf3;
  background-color: #fff;
}

.url-input-group .external-link-icon {
  height: 16px;
  width: 16px;
  color: #737b8b;
}

.url-input-group .invalid {
  pointer-events: none;
}

.url-input-group .invalid .external-link-icon {
  opacity: 0.35;
}
