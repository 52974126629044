.user-role-dropdown {
    height: 36px !important;
}

.user-role-add-dropdown-label {
    padding-right: 75px !important;
    margin-left: -4px;
}

.user-role-add-dropdown {
    width: 198px;
}