.remove-feed-button {
  height: 20px;
  width: 20px;
}

.list-group-item.feed-list-item {
  padding: 16px 24px;
}

.domain-checkbox {
  accent-color: var(--echobox_blue);
}

.form-check-input[disabled] ~ .form-check-label .current-source-radio-label {
  color: #909db5;
}

.current-source-radio-label {
  color: #0e1e39;
}
