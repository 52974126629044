.structure-body {
  width: var(--structure-width);
  max-width: var(--structure-width);
}

.body-element-content {
  padding: 12px 12px 12px 8px;
  flex-grow: 1;
  max-width: 100%;
}

.body-element-content .text-block-element {
  padding: 14px 36px 16px 36px;
}

.body-element-content .promotion-image-title {
  line-height: 20px;
  margin-top: 2px;
}

.body-element-content .element-description {
  color: var(--gray-600);
}

.body-element-content .section-dropdown-toggle,
.body-element-content .section-dropdown-toggle:hover,
.body-element-content .section-dropdown-toggle:focus,
.body-element-content
  .section-dropdown-toggle:not(:disabled):not(.disabled):active {
  background-color: transparent;
}

.drop-handle {
  color: #bfd0df;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promotion-drag-handle {
  margin-top: 2px;
}

.dragging .drop-handle {
  color: var(--echobox_blue);
}

.article-pill {
  width: 75px;
  line-height: 16px;
  white-space: nowrap;
}

.body-entities-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
