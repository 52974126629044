.verified-confirmation-modal {
  max-width: 460px;
}

.verified-confirmation-modal .success-check {
  color: var(--green-600);
}

.verified-confirmation-modal .success-check-frame {
  height: 20px;
  width: 20px;
}
