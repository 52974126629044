.property__card {
  margin: 20px 30px;
}

.property__address--padding {
  margin: 0px 44px;
}

.property__rss__highlights {
  background-color: #e3eaf3 !important;
}

.property__open__icon {
  font-size: 1rem !important;
}

.property-settings {
  width: 100%;
}

.property-settings .header {
  margin-bottom: 24px;
  margin-top: 24px;
}

.property-settings .title {
  color: #0e1e39;
  font-size: 24px;
  font-weight: 500;
}

.property-settings .form-control {
  width: 600px;
}

.property-settings .form-control-w-100 {
  width: 100%;
}

.property-settings .form-control-w-300 {
  width: 300px;
}

.property-settings .feed-input {
  background: #ffffff;
  border: 1px solid #dbe3f0;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
}

.property-settings .form-control:disabled {
  background: white !important;
  border: 1px solid #dbe3f0 !important;
  border-radius: 6px !important;
}

.property-settings-feeds .list-group-item:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.property-settings-feeds .list-group-item:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
