.time-slot-slider .MuiSlider-thumb {
  width: 12px;
  height: 12px;
}

.time-slot-slider .MuiSlider-root {
  color: var(--primary-600);
}

.time-slot-slider .MuiSlider-rail {
  color: var(--gray-300);
  opacity: 1;
  border-radius: 4px;
}
