.settings-branding .optional {
  opacity: 0.5;
}

.settings-branding .accent-color-input {
  max-width: 180px;
  height: 44px !important;
  padding-left: 32px;
  font-size: 16px !important;
  border-radius: 6px;
}

.settings-branding .accent-color {
  width: 36px;
  height: 36px;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
}

.settings-branding .logo-preview {
  padding: 10px;
  background-color: #ebeef5;
  border: 1px solid #dae2ec;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.settings-branding .logo-preview img {
  width: 256px;
}

.settings-branding .image-btn {
  color: var(--gray-900) !important;
  font-weight: 500 !important;
}

.settings-branding .header-preview {
  background-color: #ebeef5;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}

.settings-branding .header-preview img {
  width: 100%;
  border-radius: 4px;
}

.settings-branding .header-url {
  margin-top: 30px;
  margin-bottom: 8px;
  font-size: 14px !important;
}

.settings-branding .header-url-input {
  width: 50%;
}

.settings-branding .f-14 {
  font-size: 14px;
}

.settings-branding .settings-subtitle {
  font-size: 14px !important;
  color: #0e1e39 !important;
  margin-bottom: 8px;
  font-weight: 500;
}

.settings-branding .font-dropdown {
  max-width: 300px;
  margin-bottom: 20px;
}

.settings-branding .input-hash {
  position: absolute;
  left: 16px;
  top: 10.5px;
  color: #909db5;
}

.settings-branding .branding-image-actions {
  margin-bottom: 12px;
}

.settings-branding .header-image-actions {
  margin-bottom: 32px;
}
