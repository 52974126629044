.sign-up__card {
  border: 0;
  border-top: 1px solid #292d46;
  padding: 25px;
}

.sign-up__card__header {
  font-size: 20px;
}

.sign-up__card__inputs {
  font-size: 14px;
}

#show-password {
  color: #292d46;
}

.label-text {
  font-size: 13px;
}

/* Replacing hyperlink style */
#sign-in {
  color: #292d46;
}

/* Adjust borders when using a device size below 460px */
@media (min-width: 577px) {
  .sign-up__card {
    border: 1px solid #292d46;
    max-width: 460px;
    min-height: 678px;
    padding: 40px;
    width: 100%;
  }
}
