.subscribe-modal .success-check {
  margin-right: 10px;
  color: var(--green-600);
}

.rbt .rbt-input-multi.form-control {
  height: auto !important;
}

.rbt .rbt-token-removeable {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
