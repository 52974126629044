.schedule-modal-item {
  border: 1px solid #dbe3f0;
  border-radius: 6px;
}

.schedule-modal-item-selected {
  border: 1px solid var(--primary-700);
  box-shadow: 0 0 0 1px var(--primary-700);
  border-radius: 6px;
  cursor: default;
}

.schedule-modal-item:hover {
  border: 1px solid var(--primary-700);
}

.schedule-modal-item:hover,
.schedule-modal-item:hover .modal-item-label {
  color: var(--primary-700);
}

.modal-item-description {
  margin-left: 23px;
  color: var(--gray-600);
}

button .react-datepicker-wrapper {
  margin: 12px 0;
  width: 100%;
}

.input-container .react-datepicker-wrapper input {
  padding: 4px 12px;
}
