.promotion-block-image {
  max-height: 64px;
  min-width: 36px;
  min-height: 36px;
}

.promotion-block-link {
  margin-left: 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.promotion-block-ellipsis {
  flex-grow: 1;
}

.promotion-block-ellipsis-button {
  height: fit-content;
}

.add-promotion {
  text-decoration: none !important;
}
