.settings-connections .settings-title {
  font-size: 24px;
}

.settings-connections .nav-link {
  padding: 0.75rem 0;
  margin-right: 1.5rem;
}

.settings-connections .nav-link .settings-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-600) !important;
}

.settings-connections .nav-link.active .settings-title {
  color: var(--gray-900) !important;
}
