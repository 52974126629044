.success__tick {
  font-size: 8rem !important;
}

.success__height {
  height: 100vh;
}

.success__card {
  border: 1px solid #292d46;
  max-width: 460px;
  width: 100%;
}

.success__card__header {
  font-size: 20px;
  font-weight: 500;
}

.success__card__description {
  font-size: 16px;
  font-weight: 400;
}

.powered__text {
  font-size: 14px;
  font-weight: 400;
}
