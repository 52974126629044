.footer-input .rdw-editor-wrapper .rdw-editor-toolbar {
  padding: 12px;
  margin-bottom: 0;
  border: 1px solid #bfd0df;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}

.footer-input .wysiwyg-editor {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.max-width .rdw-editor-wrapper .rdw-editor-toolbar, .max-width .wysiwyg-editor {
  max-width: var(--structure-width);
}