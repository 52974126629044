.ebx-edition-subject {
  line-height: 160%;
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ebx-panel-pending .ebx-edition-subject {
  line-height: initial;
}
