.highchart-tooltip {
  background-color: white; 
  padding: 8px 12px; 
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.12), 0px 0px 1px rgba(9, 30, 66, 0.31); 
  border-radius: 0.25rem
}

.highchart-tooltip-text {
  font-size: 14px; 
  font-weight: 500; 
  margin:0px; 
  color: #0E1E39
}