.double-opt-in-switch {
  display: flex;
  align-items: center;
}

.double-opt-in-switch:not(:disabled) > * {
  cursor: pointer !important;
}

.recommended-tag {
  padding: 4px 8px;
  border-radius: 16px;
  line-height: 16px;
  font-size: 14px;
  color: var(--green-600);
  background: #d6f3e5;
}
