.article-rounded {
  border-radius: 4px !important;
}

.article-content {
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 36px;
  padding-right: 17px;
  position: relative;
  flex-grow: 1;
}

.article-section .ellipsis-button,
.article-section .promotion-block-ellipsis-button {
  display: none;
}

.article-section:hover .ellipsis-button,
.article-section:hover .promotion-block-ellipsis-button {
  display: inline-block;
}

.article-section:hover .article-title {
  text-overflow: clip;
}

.article-section:hover .article-title::before {
  content: '';
  width: 28px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to right, transparent, white);
}

.article-section {
  margin-bottom: 12px;
}

.article-section:last-child {
  margin-bottom: 0;
}

.article-section .drag-handle {
  position: absolute;
  left: 10px;
  top: 4px;
  color: #bfd0df;
}

.article-section .open-article-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 5; */
}
