.html-editor {
  min-height: 400px;
  border-radius: 0 !important;
  border-right: 0 !important;
  padding: 0px !important;
}

.monaco-editor .margin {
  border: 1px solid var(--gray-300);
  background-color: var(--gray-100) !important;
  width: 36px !important;
}

.monaco-editor .margin-view-overlays .line-numbers {
  width: 31px !important;
}
