@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  background: #f7f9fc;
  color: #424d60;
  /* Font has to be important to override bootstrap */
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 769px) {
  html {
    min-width: 1400px;
    overflow: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global button font styles */
.btn {
  font-size: 14px;
  font-weight: 500;
}

/* Global set svg color to inherit */
svg {
  fill: currentColor;
}

.busy {
  opacity: 0.5;
  pointer-events: none;
}

.error--text {
  color: #b91e43;
  font-size: 13px;
  font-weight: 500;
}

/* Global input font size */
.form-control {
  font-size: 14px !important;
  font-weight: 400 !important;
}

:root {
  --header__height: 57px;
  --subheader__height: 60px;
  --footer__height: 60px;
  --curation__sections__titles__height: 26px;
  --main-content__margin-top: calc(var(--header__height));
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #e3eaf3;
}

::-webkit-scrollbar-track {
  background-color: #e3eaf3;
}

::-webkit-scrollbar-thumb {
  background-color: #8994ae;
  border-radius: 10px;
  width: 10px;
}

.mg-subheader {
  margin-top: var(--subheader__height);
}

.stay-within-parent {
  overflow: hidden;
}

.stay-within-parent *,
.stay-within-parent > * {
  position: static !important;
}
