.campaign-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.link-unstyled {
  color: unset;
}

.link-unstyled:hover,
.link-unstyled:focus {
  color: unset;
  text-decoration: none;
}

.campaign-subheader-margin {
  margin-top: 64px;
}
