.copied__popover {
    background: #ffffff;
    border: 1px solid var(--gray-300);
    border-radius: 4px !important;
    font-family: 'Roboto', sans-serif !important;
}
.copied__popover > .arrow {
    display: none !important;
}

#snippet {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 10px;

    width: 512px;
    min-height: 61px;

    /* White */

    background: #FFFFFF;
    /* Gray/300 */

    border: 1px solid #DBE3F0;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    margin-top: 8px;
    margin-bottom: 24px;

    /* Small body text */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */


    /* Gray/900 */

    color: #0E1E39;
}

.snippet-label {
    width: 48px;
    height: 20px;

    /* Component specific styles/[Button] Text MD */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* Gray/900 */

    color: #0E1E39;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    margin-top: 24px;
}

.button-text {
    /* Component specific styles/[Button] Text MD */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    align-items: center;
    text-align: center;

    /* Gray/900 */

    color: #0E1E39;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    margin-left: 8px;
}

.custom-button {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.snippet-comment {
    width: 424px;
    height: 20px;

    /* Small body text */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Gray/600 */

    color: #596A87;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    margin-top: 8px;
}

.form-title {
    width: 172px;
    height: 32px;

    /* Heading 2 */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Gray/900 */

    color: #0E1E39;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    margin-top: 8px;
}

.embedded-form-help {
    /* Frame 2265 */
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;

    /* Gray/100 */

    background: #F7F9FC;
    /* Gray/300 */

    border: 1px solid #DBE3F0;
    border-radius: 8px;
}