.promotion-body-element {
  height: 82px;
}

.body-promotion-empty-image-background {
  background: var(--gray-100);
  max-height: 56px;
  border: 1px dashed var(--gray-300);
  max-width: 110px;
  width: 100%;
  border-radius: 3px;
}

.body-promotion-image {
  border-radius: 4px;
  max-height: 56px;
  max-width: 110px;
}

.body-promotion-image-padding {
  padding: 8px;
  max-height: 68px;
}

.body-promotion-link {
  color: #0e1e39 !important;
}

.body-promotion-link:hover {
  color: #0e1e39 !important;
}

.body-promotion-delete {
  color: var(--red-600) !important;
}

.body-promotion-delete:hover {
  color: var(--red-600) !important;
}
