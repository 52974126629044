.modal-header.loading-modal-header {
  padding: 24px;
}

.modal-title.loading-modal-title {
  font-size: 20px;
}

.modal-body.loading-modal-body {
  padding: 24px;
}

.loading-step {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  color: var(--echobox_blue) !important;
}

.loading-substep {
  margin-left: auto;
  width: 14px;
  height: 14px;
  color: var(--gray-600) !important;
}

.loading-substep-loaded {
  opacity: 0.6;
}

.loading-step.loading-step-loading {
  width: 20px;
  height: 20px;
}

.loading-substep.loading-step-loading {
  width: 14px;
  height: 14px;
}

.loading-step-waiting {
  border: 2px solid #e3eaf3;
  border-radius: 50%;
}

.loading-step-header {
  line-height: 1;
}

.loading-divider {
  margin-left: 9px;
  margin-right: 9px;
  border-radius: 1px;
  border: 1px solid #e3eaf3;
  width: 2px;
}

.loading-subsections {
  margin-left: 12px;
  flex-grow: 1;
}

.modal-footer.loading-modal-footer {
  padding: 16px 24px;
}
