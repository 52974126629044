.optimisation-options {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  width: 620px;
}

.optimisation-options-header {
  padding: 24px;
}

.optimisation-options .card-header {
  background-color: white !important;
  border: none;
  padding: 0.5rem 1.25rem !important;
}

.card-header-icon {
  width: 20px;
}

.card-header-icon-active {
  position: relative;
  top: 3px;
}

.optimisation-options .card {
  border: none;
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
  border-radius: 0;
}

.accordion-header {
  padding: 0 24px;
}

.accordion-header-icon svg {
  margin: 18px 0;
}

.optimisation-options .card-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.optimisation-options .card-title-inactive {
  color: var(--gray-500);
}

.optimisation-options .card-title-active {
  color: var(--gray-900);
}

.optimisation-options .card-body {
  padding: 0;
  margin-left: 64px;
  margin-right: 44px;
}

.optimisation-option-status {
  margin-top: auto;
  margin-bottom: auto;
}
