.sign-in__page-height {
  height: 100vh;
}

.sign-in__card {
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sign-in__card__title {
  font-size: 17px;
  font-weight: 700;
}

.sign-in__card__header {
  font-size: 20px;
  font-weight: 500;
}

#show-password {
  color: #292d46;
}

.label-text {
  font-size: 13px;
}

.footer-text {
  margin: 32px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* Replacing hyperlink style */
#sign-in {
  color: #292d46;
}

.sign-in__background {
  z-index: -1;
}

/* Adjust borders when using a device size below 460px */
@media (min-width: 577px) {
  .sign-in__card {
    max-width: 460px;
    min-width: 0;
    width: 100%;
  }
}
